/*
 * @Author: WYY 
 * @Description: 
 * @ModuleName: 
 * @Date: 2018-11-20 16:01:18 
 * @Last Modified by: WYY
 * @Last Modified time: 2020-04-02 18:50:11
 */

<style lang="less" scoped>
    .btn_list{
        display: block;
        .btn_a{
            display: inline-block;
            margin-top: -10px;
            width: 90px;
            height: 40px;
            background-image: linear-gradient(0deg, 
                #e0e0e0 0%, 
                #ffffff 100%), 
            linear-gradient(
                #e5e5e5, 
                #e5e5e5);
            background-blend-mode: normal, 
                normal;
            box-shadow: 1px 1px 3px 0px 
                rgba(21, 52, 1, 0.09);
            border-radius: 20px 20px 20px 20px;
            border: solid 1px #dddddd;
            text-align: center;
            color: #888;
            line-height: 40px;
            font-size: 14px;
        }
    }
    .T_content{
        position: relative;
    }
    .T_dengji{
        // position: absolute;
        // right: 50px;
        // bottom: 20px;
        text-align: right;
        padding-right: 20px;
        color: #666;
        font-size: 20px;
    }
</style>

<template>
    <div class="T_box">
        <div class="T_title">
            Your Location：Score Reports
        </div>
        <div class="T_content">
            <div class="T_table">
                <section class="Table_l" v-for="(item,i) in Tabel" :key='i'>
                    <h1 class="Ta_title"> <em>{{i+1}}、</em> {{item.title}} </h1>
                    <table>
                        <thead>
                            <!-- <tr v-if="!!item.choice">
                                <th></th>
                                <th colspan="6">等级</th>
                                <th colspan="6">分值</th>
                            </tr>
                            <tr v-if="!!item.choice">
                                <td>{{item.choice.title}}</td>
                                <td colspan="6">{{item.choice.level}}</td>
                                <td colspan="6">{{item.choice.score}}</td>
                            </tr> -->
                            <tr style="background-color:#c0c0c0;color:#444444">
                                <th rowspan="2">
                                    <div style="height:100%;position:relative;width:130px;height:60px;">
                                        <img src="../assets/weidu.jpg" v-if="i==0" alt="" style="width:130px;height:60px;margin:0;position:absolute;top:0;left:0;">
                                        <img src="../assets/weidu_2.png" v-if="i==1" alt="" style="width:130px;height:60px;margin:0;position:absolute;top:0;left:0;">
                                    </div>
                                </th>
                                <th colspan="2">理解</th>
                                <th colspan="2">语音</th>
                                <th colspan="2">流利</th>
                                <th colspan="2">结构</th>
                                <th colspan="2">词汇</th>
                                <th colspan="2">互动</th>
                            </tr>
                            <tr style="background-color:#c0c0c0;color:#444444">
                                <th>等级</th>
                                <th>分值</th>
                                <th>等级</th>
                                <th>分值</th>
                                <th>等级</th>
                                <th>分值</th>
                                <th>等级</th>
                                <th>分值</th>
                                <th>等级</th>
                                <th>分值</th>
                                <th>等级</th>
                                <th>分值</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(el,mi) in item.data" :key="mi">
                                <td style="padding:6px 0;">{{el.title}}</td>
                                <td style="padding:6px 0;" v-for="(ele,ni) in Ti" :key="ni">
                                    {{el[ele]}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <mark>{{item.mark}}</mark>
                </section>
                <div class="T_dengji">
                    ICAO等级：{{Score}}
                </div>
            </div>
            
        </div>
        <div class="T_option">
            <div class="Unit_option">
                <Time :Pattern='Pattern'/>
            </div>
        </div>
    </div>
</template>

<script>
    import Url from '../util.js'
    export default {
        data(){
            return {
                Tabel:[
                    {
                        title:'各种题型维度等级成绩',
                        data:[],
                        mark:'',
                        choice:{},
                    },
                    {
                        title:'维度等级成绩',
                        data:[],
                        mark:'说明：维度等级成绩为各题型该维度分值的平均值，然后再转换等级。',
                    },
                    // {
                    //     title:'ICAO等级成绩',
                    //     data:[],
                    //     mark:'说明：ICAO等级成绩为该单项等级中等级最低（分值最低）的一项作为ICAO等级成绩；',
                    // },
                ],
                type:[
                    {
                        title:"选择题",
                        key:"choice"
                    },
                    {
                        title:"单句语音",
                        key:'voice',
                    },
                    {
                        title:"简答题",
                        key:'shortAnser',
                    },
                    {
                        title:"故事复述",
                        key:'story',
                    },
                    {
                        title:"模拟通话",
                        key:'communication',
                    },
                    {
                        title:"OPI面试",
                        key:'opi',
                    },
                    {
                        title:'单项等级成绩',
                        key:'dan',
                    },
                    {
                        title:'ICAO等级成绩',
                        key:'icao',
                    },
                    {
                        title:'模拟通话',
                        key:'practice',
                    },
                ],
                Ti:['undeLevel','undeScore','pronLevel','pronScore','fluentLevel','fluentScore','gramLevel','gramScore','vocaLevel','vocaScore','inteLevel','inteScore'],
                Score:'',
            }
        },
        created() {
            this.getinfo();
            this.endTest();
        },
        methods:{
            async endTest(){
                if(Url.BaseUrl == 'http://52.83.93.14:5001/bus-pro-service'){
                    return false;
                }
                let endtime = new Date();
                endtime = window.formatDateTime(endtime);
                let data = {
                    uuid:sessionStorage.getItem('uuid'),
                    starttime:sessionStorage.getItem('starttime')||'',
                    endtime:endtime,
                    courseid:sessionStorage.getItem('curriculum'),
                }
                let url_ = await this.AjaxApi.endTest(data);
            },
            async getinfo(){
                let ti_ = await this.AjaxApi.getTotalScore();

                this.Tabel[0].data = this.DataChange(ti_);
                let ti1_ = await this.AjaxApi.getAvgScore();

                ti1_ = {
                    'dan':ti1_,
                }
                this.Tabel[1].data = this.DataChange(ti1_);
                let ti2_ = await this.AjaxApi.getIcaoScore();

                this.Score = ti2_.score;
                // ti2_ = {
                //     'icao':ti2_,
                // }
                // this.Tabel[2].data = this.DataChange(ti2_);
            },
            DataChange(data){
                let list = [];
                for (const i in data) {
                    let item = {
                        title:'',
                    }
                    for (let j = 0; j < this.type.length; j++) {
                        const element = this.type[j];
                        if( i == element.key ){
                            item.title = element.title;
                        }
                    }
                    let tar = data[i];
                    for (const key in tar) {
                        item[key] = tar[key];
                        if(item[key]=='-1'){
                            item[key] = '-';
                        }else if(item[key]=='-'){
                            
                        }else{
                            // item[key] = parseFloat(item[key]);
                        }
                    }
                    // if(i!='choice'){
                    //     list.push(item);
                    // }
                    if(i=='choice'){
                        item['undeLevel'] = tar['level'];
                        item['undeScore'] = tar['score'];
                    }
                    list.push(item);
                }
                return list;
            },
            ReStart(){
                let item = this.GetOutLayer();
                if(item.allSectionModel == 3){ // 测试模式
                    let starttime = new Date();
                    starttime = window.formatDateTime(starttime);
                    sessionStorage.setItem('starttime',starttime);
                }
                sessionStorage.setItem('checkid',item.id);
                sessionStorage.setItem('targetceng',item.ceng);
                this.$store.commit('setitem',{'name':'checkid','item':item.id})
                this.$store.commit('setitem',{'name':'outerId','item':item.id});
                this.$store.commit('setitem',{'name':'dataLayer','item':item.ceng})
                this.$router.push('/home/describe');
            },
            Next(){
                let temporary = sessionStorage.getItem('temporary');
                if(temporary == 'over'){
                    this.$message.warning('Complete all chapters !');
                }else{
                    let item = JSON.parse(temporary);
                    this.GoNext(item);
                }
            },
        },
    }
</script>

<style scoped>
    .T_box .T_content{
        height: calc(100% - 40px - 85px);
        padding: 20px 20px 0 20px;
    }
</style>